.dropdown_item_value {
    &_care_recipient:not(.remove_color) {
        color: var(--content-brand-primary-dark) !important;

        &.e-input-group.e-control-wrapper,
        &.e-input-group.e-control-wrapper>input {
            font: var(--body3-semi-bold) !important;
        }
    }

    &_employee:not(.remove_color) {
        color: var(--content-brand-tertiary-dark) !important;

        &.e-input-group.e-control-wrapper,
        &.e-input-group.e-control-wrapper>input {
            font: var(--body3-semi-bold) !important;
        }
    }
}


.raf_badge {
    &_Available {
        border-color: var(--stroke-tertiary);
        background-color: var(--surface-brand-tertiary-light);
        color: var(--content-brand-tertiary-base);
    }

    &_Unavailable {
        border-color: var(--stroke-quaternary);
        background-color: var(--surface-brand-quaternary-light);
        color: var(--content-brand-quaternary-base);
    }

    &_Preferred {
        border-color: var(--stroke-success);
        background-color: var(--surface-state-success-light);
        color: var(--content-state-success-base);
    }
}

.resource_header_item_text {
    font: var(--subtitle2-semi-bold);
    word-break: break-all;
    white-space: normal;
    text-wrap: wrap;
    color: var(--content-brand-primary-base);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        text-decoration-color: var(--stroke-primary);
    }

    &.Unassigned {
        color: var(--content-neutral-dark);
        cursor: auto;

        &:hover {
            text-decoration: unset;
        }
    }
}

.service_transaction_note {
    position: relative;

    &_content:not(.active) {
        max-height: 100px;
        overflow: hidden;
    }

    &_footer {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &_help_text {
            // font: var(--body3-semi-bold);
            // color: var(--content-brand-secondary-base);
            position: absolute;
            left: 1rem;
            background-color: #fff;
        }

        &:not(.active) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)) !important;

        }
    }
}

.billing_status_badge {
    padding: 0 0.5rem !important;
    width: 100%;
    display: inline-flex !important;
    align-items: center;
    height: 1.5rem;
    min-height: 1.5rem;
    line-height: 1.5rem;
}

.booking_status {
    & .raf_badge {
        padding: 0 0.25rem;
        font-size: 0.625rem;
    }
}

.shift_offer_dialog {
    &.rightDialog.e-dialog.rightDialog-lg.full-height.createEditForm.e-popup-open {
        width: 100%;
    }
}

@media only screen and (min-width: 576px) {
    .shift_offer_dialog {
        &.rightDialog.e-dialog.rightDialog-lg.full-height.createEditForm.e-popup-open {
            width: calc(80%);
        }
    }
}

@media only screen and (min-width: 767px) {

    .service_transaction_note {
        &_footer {
            padding: 0.5rem 1rem;

            &_help_text {
                left: 0.5rem;
            }
        }
    }

    .shift_offer_dialog {
        &.rightDialog.e-dialog.rightDialog-lg.full-height.createEditForm.e-popup-open {
            width: calc(60%);
        }
    }
}